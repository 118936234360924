































































































































import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import { Answer, MatchingSubQuestion } from '../../../types/questions.type';

@Component({})
export default class MatchingQuestionForm extends Vue {
  @Prop(Array) readonly questionAnswers!: Answer[];

  @Prop(Array) readonly questionSubQuestions!: MatchingSubQuestion[];

  validForm = false;

  $refs!: {
    matchingForm: HTMLFormElement;
  };

  rules = {
    subQuestionAnswer: [
      (v: string) => v.trim().length > 0 || this.$t('Required'),
      (v: string) => v.length <= 130 || this.$t('validations.maxLength', { max: 130 }),
    ],
    selectRules: [(v) => v.length > 0 || this.$t('requiredField')],
    degree: [
      (val: number) => val >= 0.01 || this.$t('validations.minValue', { min: 0.01 }),
      (val: number) => val <= 999 || this.$t('validations.maxValue', { max: 999 }),
      (val: string) => /^\d{0,3}(?:\.\d{1,2})?$/.test(val)
        || this.$t('validations.maxFloating'),
    ],
  };

  mounted() {
    if (!this.questionAnswers.length) {
      this.initAnswersData();
    }
  }

  get canRemoveAnswer() {
    return this.questionAnswers.length > 2;
  }

  get canRemoveSubQuestion() {
    return this.questionSubQuestions.length > 2;
  }

  get canAddAnswer() {
    return this.questionAnswers.length < 20;
  }

  get canAddSubQuestion() {
    return this.questionSubQuestions.length < 20;
  }

  get totalDegrees() {
    return this.questionSubQuestions.reduce(
      (acc, subQuestion) => Number(Number(Number(acc) + Number(subQuestion.degree)).toFixed(2)),
      0,
    );
  }

  initAnswersData() {
    this.questionSubQuestions.push(
      {
        text: '',
        sort: 0,
        correctAnswers: [],
        key: 0,
        degree: 0,
      },
      {
        text: '',
        sort: 1,
        correctAnswers: [],
        key: 1,
        degree: 0,
      },
    );

    this.questionAnswers.push(
      {
        text: '',
        sort: 0,
        valid: true,
        key: 0,
      },
      {
        text: '',
        sort: 1,
        valid: true,
        key: 1,
      },
    );
  }

  @Watch('totalDegrees', { deep: true })
  onQuestionTotalDegreeChange() {
    this.$emit('changeTotalDegree', this.totalDegrees);
  }

  addAnswer() {
    const lastKeyIndex = this.questionAnswers[this.questionAnswers.length - 1]?.key;
    this.questionAnswers.push({
      text: '',
      correct: 0,
      sort: this.questionAnswers.length,
      key: lastKeyIndex ? lastKeyIndex + 1 : this.questionAnswers.length,
    });
  }

  addSubQuestion() {
    const lastKeyIndex = this.questionSubQuestions[this.questionSubQuestions.length - 1]?.key;
    this.questionSubQuestions.push({
      text: '',
      sort: this.questionSubQuestions.length,
      key: lastKeyIndex ? lastKeyIndex + 1 : this.questionSubQuestions.length,

      correctAnswers: [],

      degree: 0,
    } as MatchingSubQuestion);
  }

  validateForm() {
    this.$refs.matchingForm.validate();
  }

  removeAnswer(ansIndex) {
    for (let index = 0; index < this.questionAnswers.length; index += 1) {
      if (index > ansIndex) {
        this.questionAnswers[index].key = (this.questionAnswers[index]?.key || 1) - 1;
      }
    }
    this.questionAnswers.splice(ansIndex, 1);

    this.updateSelectedCorrectAnswer(ansIndex);
  }

  removeSubQuestion(quesIndex) {
    for (let index = 0; index < this.questionSubQuestions.length; index += 1) {
      if (index > quesIndex) {
        this.questionSubQuestions[index].key = (this.questionSubQuestions[index]?.key || 1) - 1;
      }
    }
    this.questionSubQuestions.splice(quesIndex, 1);
  }

  updateSelectedCorrectAnswer(ansIndex: number) {
    for (let index = 0; index < this.questionSubQuestions.length; index += 1) {
      const correctAnswer = this.questionSubQuestions[index].correctAnswers;
      for (let answer = 0; answer < correctAnswer.length; answer += 1) {
        if (correctAnswer[answer] === ansIndex) {
          this.questionSubQuestions[index].correctAnswers.splice(answer, 1);
        }

        if (correctAnswer[answer] > ansIndex) {
          this.questionSubQuestions[index].correctAnswers[answer] -= 1;
        }
      }
    }
  }
}
