

























































































































































































/* eslint no-underscore-dangle: ["error", { "allow": ["_id"] }] */
import { mixins } from 'vue-class-component';
import { Component, Watch } from 'vue-property-decorator';
import DialogComponent from '@/components/dialog.component.vue';
import SubjectProvider from '../../providers/subject.provider';
import QuestionsProvider from '../../providers/questions.provider';
import {
  APPIDS, QUESION_TYPES, QUESTIONS_ROUTES_NAMES, EDIT_QUESTION_TYPE,
} from '../../enums';
import {
  TypeIloInput,
  QuestionForm,
  BasicFilterInput,
  DetailedQuestion,
  QuestionDetailsResponse,
  QuestionsFiltersResponse,
  QuestionTypeKeyFunction,
} from '../../types/questions.type';
import { Lang } from '../../types/general.type';
import { HeaderILos, FlexibleILOsData } from '../../types/ilos.type';
import QuestionAnswersForm from './QuestionAnswersForm.component.vue';
import MatchingQuestionForm from './matching/matchingColumns.vue';
import QuestionMainDataForm from './QuestionMainDataForm.component.vue';
import Toaster from '../../mixins/toaster.mixin';

@Component({
  components: {
    DialogComponent,
    QuestionAnswersForm,
    QuestionMainDataForm,
    MatchingQuestionForm,
  },
})
export default class QuestionAddEdit extends mixins(Toaster) {
  appId = APPIDS.CREATE_QUESTIONS;

  questionTypes = QUESION_TYPES;

  name = 'questionAddEdit';

  courseId = '';

  questionId = '';

  questionTypeKey = '';

  actionType = '';

  actionsEnum = {
    ADD: 'add',
    EDIT: 'edit',
    COPY: 'copy',
  };

  routesActionsEnum = {
    'question-add': 'add',
    'question-edit': 'edit',
    'question-copy': 'copy',
  };

  loading = true;

  saveLoading = false;

  validMainData = true;

  validAnswers = true;

  $refs!: {
    matchingQuestions: MatchingQuestionForm;
  };

  question: QuestionForm = {
    title: '',
    text: '',
    degree: 1,
    difficulty: 0,
    langId: '',
    time: '',
    training: 0,
    typeId: '',
    subjectId: '',
    answers: [],
    numberOfLines: 3,
    rubrics: [],
    ilosHeaderId: '',
    ilos: {
      '1.1.': [],
      '1.2.': [],
      '1.3.': [],
      '1.4.': [],
    },
    matchingSubQuestions: [],
    quesVersion: 1,
    ilosIds: [],
    drawAnswersLines: false,
  };

  filtersData: QuestionsFiltersResponse = {
    iloHeaders: [],
    languages: [],
    subjects: [],
  };

  subjectILOS: HeaderILos | FlexibleILOsData[] = {};

  // subjectFlexibleILOS: FlexibleILOs = {};

  isFlexibleILOs = false;

  ilosLoading = false;

  showSaveConfirm = false;

  showBackConfirm = false;

  editingType: number | null = null;

  EDIT_TYPE = EDIT_QUESTION_TYPE;

  systemLanguage = null;

  isMajorChange = false;

  questionAnalyticsData: {
    answersCount: number;
    sortedCorrectAnswers: string;
    subQuestionsCount: number;
    subQuestionsCorrectAnswers: {
      [subQuestionId: string]: {
        subQuestionAnswersCount: number;
        subQuestionSortedCorrectAnswers: string;
      };
    };
  } = {
    answersCount: 0,
    sortedCorrectAnswers: '',
    subQuestionsCount: 0,
    subQuestionsCorrectAnswers: {},
  };

  get disableSubmitBtn(): boolean {
    return this.actionType === this.actionsEnum.EDIT && this.editingType === null;
  }

  get appDrawerOpen() {
    return !this.$store.state.app.mini;
  }

  updated() {
    this.systemLanguage = this.$store.state.lang.lang;
  }

  async mounted() {
    this.systemLanguage = this.$store.state.lang.lang;
    this.courseId = this.$route.params.courseId;
    this.question.typeId = this.$route.params.questionType || '';
    this.questionTypeKey = (
      QUESION_TYPES[this.question.typeId] as QuestionTypeKeyFunction
    )().label as string;
    const routeName = this.$route?.name || '';
    this.actionType = this.routesActionsEnum[routeName];
    if (this.actionType && this.actionType !== this.actionsEnum.ADD) {
      this.questionId = this.$route.params.questionId || '';

      await this.fetchQuestionData(this.questionId);
      await this.fetchSubjectIlos(this.question.subjectId, true);
    } else {
      this.fetchQuestionData();
    }
  }

  formalizeQuestionData(question: DetailedQuestion) {
    const formattedQuestion = {
      title: question.basicData?.title || '',
      text: question.basicData?.text || '',
      degree: question.basicData?.degree,
      difficulty: question.basicData?.difficulty?.value || 0,
      langId: question.basicData?.language?.id || '',
      time: question.basicData?.time,
      training: question.basicData?.training || 0,
      typeId: this.question.typeId || question.basicData?.type?.id,
      subjectId: question.subject?.id?.toString() || '',
      numberOfLines: question.numberOfLines || 3,
      isFlexibleILOs: !!question.basicData?.isFlexibleILOs,
      ilosHeaderId: question.basicData?.isFlexibleILOs
        ? question.basicData?.ilosHeaderId?.toString() || ''
        : question.ilosHeaderId?.id?.toString() || '',
      ilosIds: question.ilosIds || [],
      ilos: question.ilos,
      answers: question.answers || [],
      matchingSubQuestions: question.matchingSubQuestions || [],
      rubrics:
        question.rubrics?.map((rubric) => ({
          text: rubric.text || '',
          weight: (rubric.weight && Number(rubric.weight)) || 0.1,
        })) || [],
      quesVersion: question.quesVersion,
      drawAnswersLines: question.basicData?.drawAnswersLines ? 1 : 0,
    };
    return formattedQuestion;
  }

  setMainDataValidation(isValid) {
    this.validMainData = isValid;
  }

  setAnswersValidation(isValid) {
    this.validAnswers = isValid;
  }

  goBack() {
    if (this.actionType && this.actionType !== this.actionsEnum.ADD) {
      this.$router.push({
        name: QUESTIONS_ROUTES_NAMES.VIEW,
        params: {
          questionId: this.questionId,
          questionType: this.question.typeId || '',
          courseId: this.courseId,
        },
      });
    } else {
      const appFilters = this.$store.state.filters.appFilters[this.appId];
      this.$router.push({
        name: QUESTIONS_ROUTES_NAMES.QUESTIONS_LIST,
        query: appFilters,
        params: {
          courseId: this.courseId,
        },
      });
    }
  }

  resetIlos() {
    Object.keys(this.subjectILOS).forEach((key) => {
      this.subjectILOS[key] = [];
    });
    this.clearQuestionIlos();
  }

  clearQuestionIlos() {
    Object.keys(this.question.ilos as Record<string, HeaderILos>).forEach(
      (key) => {
        if (this.question.ilos) {
          this.question.ilos[key] = [];
        }
      },
    );
  }

  async fetchSubjectIlos(subjectId, keepQuestionIlos = false) {
    try {
      this.ilosLoading = true;
      const { ilos, isFlexibleILOs } = await SubjectProvider.getCourseIlos(
        this.appId,
        this.courseId,
        subjectId,
      );
      this.ilosLoading = false;
      this.isFlexibleILOs = isFlexibleILOs;

      if (isFlexibleILOs) {
        this.subjectILOS = ilos as FlexibleILOsData[];
        this.question.ilos = [];
        // if (Array.isArray(this.subjectILOS)) {
        //   (this.subjectILOS as any[]).sort((a, b) => {
        //     if (a.name < b.name) {
        //       return -1;
        //     }
        //     if (a.name > b.name) {
        //       return 1;
        //     }
        //     return 0;
        //   });
        // }
      } else {
        this.subjectILOS = this.filterSubjectILOS(ilos as HeaderILos);
      }

      if (!keepQuestionIlos) {
        this.clearQuestionIlos();
      }
    } catch (err) {
      this.ilosLoading = false;
    }
  }

  // filterSubjectFlexibleILOS(ilos: FlexibleILOsData[]): FlexibleILOsData[] {
  //   // const jsonIlos = JSON.parse(JSON.stringify(ilos));

  //   const filteredNodes = ilos.filter((node) => {
  //     const newNode = JSON.parse(JSON.stringify(node)); // create a new object instead of modifying the 'node' parameter directly
  //     if (newNode.selected || this.hasSelectedDescendants(newNode)) {
  //       newNode.children = this.filterSubjectFlexibleILOS(newNode.children);
  //       return true;
  //     }
  //     return false;
  //   });

  //   return filteredNodes;
  // }

  // hasSelectedDescendants(node: FlexibleILOs): boolean {
  // // Check if node has any selected descendants
  //   const jsonIlos2 = JSON.parse(JSON.stringify(node.children));
  //   if (jsonIlos2.some((child) => child.selected)) {
  //     return true;
  //   }
  //   // Recursively check all descendant nodes
  //   return jsonIlos2.some((child) => this.hasSelectedDescendants(child));
  // }

  filterSubjectILOS(ilos: HeaderILos) {
    return Object.keys(ilos as Record<string, HeaderILos>).reduce(
      (acc, iloHeader) => {
        if (ilos[iloHeader]?.length) {
          return { ...acc, [iloHeader]: ilos[iloHeader] };
        }
        return acc;
      },
      {},
    );
  }

  openLocalStorage(itemName: string) {
    return JSON.parse(localStorage.getItem(itemName) || '');
  }

  saveLocalStorage(itemName: string, itemData) {
    localStorage.setItem(itemName, JSON.stringify(itemData));
  }

  removeFromLocalStorage(itemName: string) {
    localStorage.removeItem(itemName);
  }

  created() {
    if (localStorage.question) {
      this.question = this.openLocalStorage('question');
    }
  }

  destroyed() {
    if (localStorage.question) {
      this.removeFromLocalStorage('question');
    }
  }

  @Watch('question', { deep: true })
  onQuestionDataChange(question) {
    if (this.actionType && this.actionType === this.actionsEnum.ADD) {
      this.saveLocalStorage('question', question);
    }
  }

  changeTotalDegree(degree) {
    this.question.degree = degree;
  }

  async fetchQuestionData(questionId?: string) {
    try {
      const requestsArr: Promise<
        QuestionDetailsResponse | QuestionsFiltersResponse | QuestionForm
      >[] = [
        QuestionsProvider.getQuestionFilters(this.appId, this.courseId, {
          onlyActiveSubjects: true,
        }),
      ];

      if (questionId) {
        requestsArr.push(
          QuestionsProvider.getQuestionDetails(
            this.appId,
            this.courseId,
            this.questionId,
            { questionType: this.question.typeId },
            true,
          ),
        );
      } else {
        requestsArr.push(
          QuestionsProvider.getQuestionDefaultValues(
            this.appId,
            this.courseId,
            { questionType: this.question.typeId },
          ),
        );
      }

      const [filtersData, questionData] = await Promise.all(requestsArr);
      this.filtersData = filtersData as QuestionsFiltersResponse;
      if (questionId) {
        const { question }: QuestionDetailsResponse = questionData as QuestionDetailsResponse;
        this.question = this.formalizeQuestionData(
          question as DetailedQuestion,
        );
        this.isFlexibleILOs = !!this.question.isFlexibleILOs;
        if (
          question?.basicData?.type?.id === '3.4.'
          && question.answers?.length === 1
        ) {
          question.answers.push({
            text: '',
          });
        }
        if (question?.subject) {
          this.pushQuestionSubjectIfNotExist(question.subject);
        }
        if (this.actionType === this.actionsEnum.EDIT) {
          this.setQuestionAnalyticData();
        }
      } else {
        const defaults = questionData as QuestionForm;
        this.question.degree = defaults.degree;
        this.question.difficulty = defaults.difficulty;
        this.question.langId = defaults.langId;
        this.question.time = defaults.time;
      }
      this.loading = false;
    } catch (err) {
      this.loading = false;
    }
  }

  setQuestionAnalyticData() {
    if (this.actionType === this.actionsEnum.EDIT && this.question && this.question.typeId) {
      if (this.question.typeId === QUESION_TYPES.MATCHING.id) {
        if (this.question.answers && this.question.answers.length) {
          this.questionAnalyticsData.answersCount = this.question.answers.length;
        }
        if (this.question.matchingSubQuestions && this.question.matchingSubQuestions.length) {
          this.questionAnalyticsData.subQuestionsCount = this.question.matchingSubQuestions.length;
          this.question.matchingSubQuestions.forEach((subQuestion) => {
            if (subQuestion._id) {
              this.questionAnalyticsData.subQuestionsCorrectAnswers[subQuestion._id] = {
                subQuestionAnswersCount: 0,
                subQuestionSortedCorrectAnswers: '',
              };
              this.questionAnalyticsData.subQuestionsCorrectAnswers[subQuestion._id].subQuestionAnswersCount = subQuestion.correctAnswers.length;
              this.questionAnalyticsData.subQuestionsCorrectAnswers[subQuestion._id].subQuestionSortedCorrectAnswers = subQuestion.correctAnswers.sort().join(',');
            }
          });
        }
      } else if (this.question.answers && this.question.answers.length) {
        this.questionAnalyticsData.answersCount = this.question.answers.length;
        const correctAnswersArr: string[] = [];
        this.question.answers.forEach((answer) => {
          if (answer.id && answer.correct) {
            correctAnswersArr.push(String(answer.id));
          }
        });
        this.questionAnalyticsData.sortedCorrectAnswers = correctAnswersArr.sort().join(',');
      }
    }
  }

  pushQuestionSubjectIfNotExist(subject: BasicFilterInput) {
    const alreadyExist = this.filtersData.subjects?.some(
      (subj) => subj.id?.toString() === subject.id?.toString()
    );
    if (!alreadyExist) {
      if (this.actionType === 'edit' && this.filtersData.subjects) {
        this.filtersData.subjects.push({
          id: subject.id,
          name: subject.name as string,
        });
      } else {
        this.question.subjectId = '';
      }
    }
  }

  saveQuestion() {
    const reqData = { question: this.formalizeReqQuestion() };
    if (
      [this.actionsEnum.ADD, this.actionsEnum.COPY].includes(this.actionType)
    ) {
      this.createQuestion(reqData);
    } else if (this.actionType === this.actionsEnum.EDIT) {
      this.editQuestion(reqData);
    }
  }

  formalizeReqQuestion() {
    return {
      ...this.question,
      numberOfLines:
        this.question.typeId === QUESION_TYPES.ESSAY.id
          ? this.question.numberOfLines
          : 0,
      answers: this.question.answers?.filter((ans) => ans.text).map((answer, answerIndex) => ({
        ...answer,
        sort: answerIndex,
      })) || [],
      ilosHeaderId: this.formalizeReqIlosHeaderId(),
      ilos: this.formalizeReqIlos(),
      editingType: this.editingType,
    };
  }

  formalizeReqIlosHeaderId() {
    if (!this.isFlexibleILOs) {
      return Object.keys(this.question.ilos as object).reduce((ilosHeaderAcc: string, key) => {
        if (this.question.ilos && this.question.ilos[key].length) {
          return key;
        }
        return ilosHeaderAcc;
      }, '');
    }
    return this.question.ilosHeaderId || '';
  }

  formalizeReqIlos() {
    if (this.isFlexibleILOs) {
      return this.question.ilosHeaderId
        ? this.question.ilos
        : [];
    }
    return Object.keys(this.question.ilos as object).reduce(
      (ilosAcc: TypeIloInput[], key) => {
        if (this.question.ilos) {
          return [
            ...ilosAcc,
            ...this.question.ilos[key].map((ilo) => ({
              iloId: ilo.iloId,
              subIloId: ilo.subIloId ? ilo.subIloId : null,
            })),
          ];
        }
        return ilosAcc;
      },
      [],
    );
  }

  async createQuestion(reqData) {
    try {
      this.saveLoading = true;
      const { questionId, message } = await QuestionsProvider.createQuestion(
        this.appId,
        this.courseId,
        reqData,
        { questionType: this.question.typeId },
      );
      this.saveLoading = false;
      this.showSaveConfirm = false;
      this.successToaster(message as Lang, { duration: 5000 });
      this.goQuestionDetails(questionId);
    } catch (err) {
      this.saveLoading = false;
      this.showSaveConfirm = false;
      this.errorToaster(err as Lang, { duration: 5000 });
    }
  }

  async editQuestion(reqData) {
    try {
      this.saveLoading = true;
      const { questionId, message } = await QuestionsProvider.editQuestion(
        this.appId,
        this.courseId,
        this.questionId,
        reqData,
        { questionType: this.question.typeId },
      );
      this.saveLoading = false;
      this.showSaveConfirm = false;
      this.successToaster(message as Lang, { duration: 5000 });
      this.goQuestionDetails(questionId);
    } catch (err) {
      this.saveLoading = false;
      this.showSaveConfirm = false;
      this.errorToaster(err as Lang, { duration: 5000 });
    }
  }

  goQuestionDetails(questionId) {
    this.$router.push({
      name: 'question-details',
      params: {
        questionId,
        questionType: this.question.typeId || '',
        courseId: this.courseId,
      },
    });
  }

  submitQuestion() {
    this.editingType = null;
    if (this.actionType === this.actionsEnum.EDIT) {
      this.isMajorChange = this.checkIsMajorChange();
    }
    if (this.question.typeId === QUESION_TYPES.MATCHING.id) {
      this.$refs.matchingQuestions.validateForm();
      if (this.$refs.matchingQuestions.validForm) {
        this.showSaveConfirm = true;
      }
    } else {
      this.showSaveConfirm = true;
    }
  }

  checkIsMajorChange(): boolean {
    this.isMajorChange = false;
    if (this.question && this.question.typeId) {
      if (this.question.typeId === QUESION_TYPES.MATCHING.id) {
        if (this.question.answers && this.question.answers.length) {
          if (this.question.answers.length !== this.questionAnalyticsData.answersCount) {
            return true;
          }
          for (let answerIndex = 0; answerIndex < this.questionAnalyticsData.answersCount; answerIndex += 1) {
            if (!this.question.answers[answerIndex]._id) {
              return true;
            }
          }
        }
        if (this.question.matchingSubQuestions && this.question.matchingSubQuestions.length) {
          if (this.question.matchingSubQuestions.length !== this.questionAnalyticsData.subQuestionsCount) {
            return true;
          }
          for (let subQuestionIndex = 0; subQuestionIndex < this.questionAnalyticsData.subQuestionsCount; subQuestionIndex += 1) {
            const subQuestion = this.question.matchingSubQuestions[subQuestionIndex];
            if (!(subQuestion._id && this.questionAnalyticsData.subQuestionsCorrectAnswers[subQuestion._id] !== undefined)) {
              return true;
            }
            if (!(subQuestion.correctAnswers.length === this.questionAnalyticsData.subQuestionsCorrectAnswers[subQuestion._id].subQuestionAnswersCount
              && subQuestion.correctAnswers.sort().join(',') === this.questionAnalyticsData.subQuestionsCorrectAnswers[subQuestion._id].subQuestionSortedCorrectAnswers)) {
              return true;
            }
          }
        }
      } else if (this.question.answers && this.question.answers.length) {
        if (this.question.answers.length !== this.questionAnalyticsData.answersCount) {
          return true;
        }
        const correctAnswersArr: string[] = [];
        for (let answerIndex = 0; answerIndex < this.questionAnalyticsData.answersCount; answerIndex += 1) {
          if (!this.question.answers[answerIndex].id) {
            return true;
          }
          if (this.question.answers[answerIndex].correct) {
            correctAnswersArr.push(String(this.question.answers[answerIndex].id));
          }
        }
        if (correctAnswersArr.sort().join(',') !== this.questionAnalyticsData.sortedCorrectAnswers) {
          return true;
        }
      }
    }
    return false;
  }

  ilosHeaderIdChangedHandler(value) {
    this.question.ilosHeaderId = value;
    if (!value) {
      this.question.ilos = [];
    }
  }

  handleValueChanged(value) {
    this.question.ilos = value;
  }
}
